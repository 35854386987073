<template>
	<div class="agreement">
		<div v-html="content"></div>
	</div>
</template>

<script>
export default {
	data: () => ({
		content: `<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">更新日期：2023年</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">月</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">21</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">日</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">生效日期：2023年</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">月</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">21</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">日</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <br/>
</p>
<p class="paragraph text-align-type-center" style="margin: 4px 0;text-align: center;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 19px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">隐私政策</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <br/>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">【特别提示】请仔细阅读《</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">隐私政策》（尤其是加粗划线的内容）并确定解我们对您个人信息的处理规则。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">北京微联动网络科技有限公司</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">及其关联公司（下称“我们”）非常重视用户的隐私和个人信息保护。您在使用我们的服务时，我们可能会收集和使用您的相关信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">我们希望通过本《</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、对外提供和转让这些信息，以及 我们为您提供的查询、复制、更正、补充、删除和保护这些信息、行使您的权利的方式。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">本隐私政策适用范围包括但不限于</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline"> App、</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">网站、</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline"> H5 页面以及</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">数字营销服务小程序（以下统称“</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">”）。不适用于通过接入</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">以向您提供产品或服务的第三方所收集的信息。</span> 
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">本隐私政策将帮助您了解以下内容：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">一、我们如何收集和使用您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">二、我们如何使用 Cookies 和同类技术</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">三、第三方产品或服务如何获得您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">四、我们如何对外提供、转让、公开披露您的个人信息 五、我们如何保护和保存您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">六、您如何管理个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">七、未成年人的个人信息保护</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">八、通知和修订</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">九、如何联系我们</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">本隐私政策与您所使用</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理 解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">本隐私政策中涉及的相关术语，我们努力用通俗易懂和简明扼要的文字或表述，并对本隐私政策中与您的权益存在重大关系的条款和个人信息采用加粗处理以提示您注意。</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">您在点击“确认”按钮后，就表示您同意我们按照本隐私政策处理您的个人信息。如您对本隐私政策有任何疑问、意见和建议，可通过本隐私政策第九条提供的联系方式与我们联系。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">一、我们如何收集和使用您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（一）我们如何收集您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">我们仅会出于以下目的，收集和使用您的个人信息：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">1、 用户注册 </span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">当您注册</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">账户时，我们需要收集您使用的手机号，我们将委托合作方通过发送短或通过语音信验证码的方式来验证您的身份是否有效。此外，我们可能从第三方获取您授权共享的第三方账户信息（如头像、昵称、联系方式），使您可以通过第三方账户直接登录并使用我们的服务或产品。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">2、 实名认证</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">当您进行实名认证时，我们需要收集使用您的头像、真实姓名、身份证号、医院、科室、职称、医术介绍、执业证照片、身份证照片，我们将委托有服务能力的第三方对上述信息进行认证。我们收集您的上述信息是为了确认您的医生身份，获取官方认证，进而向您提供针对医务人员的服务功能。如您不提供这类信息，您将无法使用仅向医务人员开放的服务功能，但不影响您正常使用我们的其他服务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">3、 订阅功能</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">当您使用订阅功能时，我们需要收集使用您订阅的兴趣内容。我们收集您的上述信息是为了向您展示您订阅的内容。如您不提供这类信息，您将无法使用订阅功能，但不会影响您正常使用我们的其他业务功能。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">4、数字化营销</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">当您浏览</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">的数字化营销服务时，我们需要收集使用您的</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">网络行为轨迹（如点击次数、点击时间）</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">，我们收集您的上述信息是为了优化</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">数字化营销服务的内容。如您不提供这类信息，您将无法正常浏览和使用</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">数字化营销服务，但不会影响您正常使用我们的其他业务功能。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">当您参与</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">的学术活动时，我们将收集使用您选择的素材文章、图片、幻灯片等。当您主动上传素材时，如素材中包含其他人的个人信息，请您确认您已按法律规定获得了他人的授权同意，授权范围包括向我们提供相应信息以及按您选择的服务内容处理相应信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">5、保障网络和服务的安全</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">为了保障网络和服务的安全性，我们会收集您的设备识别符、IP 地址、访问日期和时间。我们收集您的上述信息是为了保障我们的产品与/或服务运行安全，如您不提供上述信息，则我们无法保证您在使用期间的我们的产品与/或服务运行安全。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">6、 支付功能</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">您可在长颈鹿智慧医学平台提供相应的线上服务。在您提供该服务的过程中可能会需要进行财务结算，我们可能会收集您的</span><span style="font-size: 13px;font-family: 微软雅黑;font-weight: bold;letter-spacing: 0;vertical-align: baseline">银行卡信息</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">用于转账。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <br/>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（二）设备权限调用情况</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">在您使用</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">过程中，我们需要在必要范围内向您申请获取设备权限。</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">请您知悉， 我们不会默认开启您设备的权限，仅在您主动确认开启的情况下，</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">才有可能通过设备权限收集您的信息。</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">调用的权限、调用权限的目的，以及调用权限前向您询问的情况如下：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">1. 基于设备信息权限的附加业务功能</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">当您使用</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">时，我们可能会收集与设备有关的信息，包括您的硬件型号、操作系统版本、唯一设备识别码（IMEI/ANDROID_ID/UUID/GUID）以及电话号码在内的移动网络信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">2. 基于设备相册权限的附加业务功能</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">当您使用上传/更新账号头像、上传身份证照片、上传执业证照片、图文/视频的制作和发布、阅读文章、意见反馈业务功能时，我们将需要获取您的设备相册权限，并收集您提供的图片内容信息。相册权限是您设备上的一项设置，您可以通过设备设置页面进行管理。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的图片、视频内容信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">3. 基于设备相机权限的附加业务功能</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">当您使用拍摄、上传/更新账号头像、上传/更改实名认证信息、图文/视频制作与发布、直播、评论业务功能时，我们将需要获取您的设备相机权限，并收集您提供的图片、视频内容信息。相机权限是您设备上的一项设置，您可以通过设备设置页面进行管理。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的图片、视频内容信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">1、 为订立、履行您作为一方当事人的合同所必需；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">2、 为履行法定职责或者法定义务所必需；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">3、 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">4、 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">5、 依照本法规定在合理的范围内处理您自行公开或者其他已经合法公开的个人信息；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">6、 法律、行政法规规定的其他情形。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（四）您个人信息使用的规则</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">2、</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">我们收集的敏感个人信息已在本隐私政策中通过显著标识进行展示；如需获取您单独同意的，我们将在收集前获取您的单独同意。请您知悉，一旦您的敏感个人信息被泄露、篡改或被非法使用，将会对您的人身或财产安全造成损害。为防止您的敏感个人信息被泄露或被非法使用，我们已采取适当的技术和管理保护措施，尽力保障您的个人信息安全。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">3、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化、匿名化处理，匿名化处理的信息将无法识别主体。</span> 
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">4、</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">在您注销账号时，我们将停止使用并删除您的个人信息，届时如果您重新注册账号时我们无法恢复您之前的信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">5、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众、研究机构、药企或其他第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">6、随着我们的产品与服务的升级迭代，我们可能会对本政策描述的业务功能及对应收集使用的个人信息类型进行调整。当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。当我们要将您的个人信息用于与原处理目的无直接或合理关联的场景，或本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，我们会通过更新本政策、相关产品页面等隐私文档并通过弹窗、站内信、推送信息等多种方式向您告知，并事先征求您的同意；</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">如该信息为敏感个人信息，我们将取得您的单独同意。</span>
</p>
<p class="paragraph text-align-type-justify" style=";text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">7、当我们展示您的个人信息时，我们会采用包括去标识化等处理方式对您的信息进行脱敏，降低您的信息在展示时的泄露风险，以保护您的信息安全。</span>
</p>
<p class="paragraph text-align-type-justify" style=";text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">8、当您使用我们产品时，可能会将包含有您个人信息的用户数据提供给与医阵营有提供联合服务关系的其他公司、组织或个人（</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">包含但不仅限于：银川麦迪卫康互联网医院有限公司等）</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">，这些主体在为您提供服务中将会获取您的个人信息数据。我们在处理上述情形时将按照提供相关服务所必须的最低限度向这些主体提供您的个人信息数据，同时，我们会通过更新本政策、相关产品页面等隐私文档并通过弹窗、站内信、推送信息等多种方式向您告知，并事先征求您的同意；</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">如该信息为敏感个人信息，我们将取得您的单独同意。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">9</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">、</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">二、我们如何使用 Cookies 和同类技术</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">1. 我们如何使用 Cookies</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">(1) 通过使用 Cookies，我们向用户提供安全且具个性化的服务体验。我们和第三方合作伙伴会在您的计算机或移动设备上存储 Cookies、Flash Cookies，或浏览器（或关联应用程序）提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（以上合称“Cookies”）。我们和我们的第三方合作伙伴可能通过 Cookies 收集您的信息，并将信息用于以下用途：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 安全类 Cookies：Cookies 可帮助我们保障产品和服务的安全和高效运转。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">(2) 第三方合作伙伴通过 Cookies 收集和使用您的信息不受本政策约束，而是受到其自身的信息保护声明约束，我们不对第三方的 Cookies 或同类技术承担责任。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">(3) 如果您的浏览器或浏览器附加服务允许，您可修改对 Cookies 的接受程度或拒绝我们的 Cookies。有关详情，请参见 https://www.aboutcookies.org/。但如果您这么做，在某些情况下可能会影响您安全使用我们的产品或服务。同时，您仍然将收到商品或服务信息，只是这些商品或服务信息与您的相关性会降低。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">2. 我们如何使用 Session</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">(1) 通过使用Session，我们向用户提供方便快捷的服务体验。当您在使用我们的应用程序、web 网页时，我们会将您的登录信息存储到服务器session对象中，不会做持久性保留，并短暂将信息用于以下用途：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 保障登录的安全性。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">三、第三方产品或服务如何获得您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">为了向您提供更丰富和完整的功能，我们会使用下述第三方软件开发工具包（简称“SDK”）为您提供服务。SDK 的具体信息收集和使用情况请以 SDK 运营方的隐私政策或相关声明为准。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">1. 微信 SDK：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 信息获取：设备软件信息、设备识别码、设备相关应用信息；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 使用目的：微信登陆；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 隐私协议地址：https://weixin.qq.com/cgi</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">2. 腾讯 super 播放器 SDK：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 信息获取：设备软件信息、设备识别码、设备相关应用信息；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 使用目的：直播；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 隐私协议地址：https://cloud.tencent.com/document/product/301/11470；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">3. ShareSDK：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 信息获取：设备软件信息、设备识别码、设备相关应用信息；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 使用目的：获得三方登陆权限，分享三方平台；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">l 隐私协议地址：https://www.mob.com/about/policy。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">四、我们如何对外提供、转移、公开披露、委托处理您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（一）对外提供</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">1、我们致力于取得和维护您对我们的信任。未经您的单独同意或不具备其他合法事由，我们不会向其他公司、组织或个人提供您的个人信息。如我们因情况变化，需要对外提供您个人信息的，我们会就相关信息对您进行告知并征得您的单独同意。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">2、我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，对外提供您的信息。如我们为履行法定义务而向第三方提供您的个人信息的，我们将在相应页面或通过其他可行方式以适当方式告知您个人信息接收方的名称和联系信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">3、为应对突发公共卫生事件，或者紧急情况下为保护您的生命健康和财产安全所必需，我们向第三方提供您的个人信息的，我们会在适当时机（如紧急情况消除后）向您告知有关信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">4、对我们与之提供个人信息的公司、组织和个人，我们会与其签署严格的共享协议定，要求接收方按照法律的规定、我们的说明以及本隐私政策的内容处理您的个人信息，要求接收方变更处理目的、处理方式时重新获得您的同意。同时我们会在对外提供您的个人信息前对接受方进行个人信息保护影响评估，以审查接收方的处理目的、处理方式等是否合法、正当、必要，对您个人权益的影响及安全风险，以及接收方是否采取了合法、有效与风险程度相使用的管理措施和技术措施保障个人信息的安全。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（二）转移</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">在涉及合并、分立、解散、被宣告破产等原因需要转移您的个人信息时，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。同时，我们会告知您上述主体的名称以及联系方式。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（三）公开披露 除非事先获得您的单独同意或具备其他合法事由，我们不会公开披露您的个人信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">（四）委托处理</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">我们可能会委托第三方服务提供者代表我们处理您的信息，以便第三方服务提供者帮助我们提供专业服务，包括物流服务等。上述委托处理行为受本政策中所声明目的约束，并且我们会通过书面协议、审计等方式要求受托机构要求遵守严格的保密义务及采取有效的保密措施，禁止其将受托处理的信息用于受托目的之外的用途。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（五）请注意，您在使用我们服务时自愿对外提供甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您谨慎对外提供或公开，如所涉信息包含他人个人信息，请您在对外提供或公开前取得他人的单独同意。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（六）请知悉，根据法律规定，若我们采取技术措施和其他必要措施处理个人信息，使得数据接收方无法重新识别特定个人且不能复原，经上述处理后的数据的共享、转让、公开披露无需另行向您通知并征得您的同意。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">五、我们如何保护和保存您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（一） 存储地点</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将按照国家相关规定，在满足出境条件的基础上，向您充分告知境外接受方的情况，并征求您的单独同意或依赖其他合法事由。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（二）存储期限</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">我们仅在为提供服务之目的所必要的最短期间内保存您的个人信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（三）技术措施与数据保护措施</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受 SSL 加密保护；我们同时对</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">客户端提供 https 安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（四）安全事件处理</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本隐私政策第九条中约定的联系方式联络我们，以便我们采取相应措施。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">六、您如何管理个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（一）查询、复制您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">您有权查询、复制您的个人信息，可以通过“设置-个人信息”查询您的个人信息，同时您也可以根据本政策第九条提供的联系方式与我们联系查询或复制您的个人信息在符合相关法律规定且技术可行的前提下，我们将在合理的期限内，通过适当的方式向您提供可复制的个人信息副本。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（二）更正、补充您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">当您发现我们处理的关于您的个人信息有错误或不完整时，您有权要求我们做出更正或补充。您可以通过“设置-个人信息”进行更正或补充，或通过本政策第九条提供的联系方式与我们联系。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（三）删除您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">在以下情形中，您可以向我们提出删除个人信息的请求：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">1、</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">如果我们处理个人信息的行为违反法律法规；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">2、基于同意处理您的个人信息，您撤回同意的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">3、如果我们处理个人信息的行为违反了与您的约定；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">4、如果我们的处理目的已经实现，无法实现或者为实现处理目的不再必要使用您的个人信息；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">5、如果您不再使用我们的产品或服务，或您注销了账号；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">6、如果我们不再为您提供产品或服务，或保存期限已经届满；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">7、法律法规规定的其他情形。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。如果您想行使数据删除权，可通过本政策第九条提供的联系方式与我们联系。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（四）改变您授权同意的范围</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">每个业务功能需要一些基本的个人信息才能得以完成，对于额外收集的个人信息的收集和使用，您可以随时给予或撤回您的授权同意您可通过本政策第九条提供的联系方式与我们联系进行授权变更。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">请您理解，</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">可能需要开启部分权限并收集必要的个人信息才能得以实现。当您撤回您的同意时，我们将无法继续为您提供撤回同意所对应的服务，但不会影响此前基于您的授权而开展的个人信息处理服务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（五）转移您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">若您需要将您的个人信息转移至其他平台、企业或组织，您可以通过本政策提供的联系方式与我们联系。我们将对您的请求进行审核，在符合网信部门规定的条件且在技术可行的前提下，我们将为您提供转移相应个人信息的途径。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（六）注销账户 </span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">如果您希望注销您的账户，您可以通过“我的-设置-通用-注销账户”进行访问，或通过本隐私政策第九条载明的方式与我们联系，我们将及时响应您的请求。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">您注销上述账户的行为是不可逆的，我们将停止为您提供产品或服务，不再收集您的个人信息，并依据您的要求删除与您账户相关的个人信息或做匿名化处理。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（七）解释说明权</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">如您对我们的个人信息处理规则存在疑问，您可以通过本政策提供的联系方式与我们联系，我们会对有关个人信息处理规则向您进行解释说明。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（八）限制或拒绝处理权</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">在符合法律规定的前提下，您有权限制或者拒绝我们对您的个人信息进行处理。如您想要行使该权利，您可以通过本政策提供的联系方式与我们联系，我们会在收到您的请求后尽快处理回复。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（九）特殊情况下对您的近亲属请求的响应</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">根据法律规定，我们建立有去世用户的个人信息保护制度。去世用户的近亲属为了其自身的合法、正当利益，可以根据本政策提供的联系方式与我们联系，并对去世用户的相关个人信息行使本规则规定的查阅、复制、更正、补充、删除等权利。在响应去世用户近亲属的请求，我们将通过严格的流程对其身份和行权目的进行审核。为了充分保护去世用户的个人信息权益，申请行使本条权利的去世用户近亲属需要根据我们的要求提交相应材料，包括：【信息类型，例如：去世用户的身份证明文件、死亡证明文件、申请人的身份证明文件、申请人与去世用户的亲属关系证明文件、申请行使的权利种类及目的。】</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">如您不同意您的近亲属在您去世后处理您的个人信息，您可以根据本政策提供的联系方式与我们联系，来说明您的安排。</span> 
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">（十）响应您的上述请求</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在十五天内做出答复。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">对于您合理的请求，我们原则上不收取费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">在以下情形中，我们将无法响应您的请求：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">1. 与我们履行法律法规规定的义务相关的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">2. 与国家安全、国防安全直接相关的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">3. 与公共安全、公共卫生、重大公共利益直接相关的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">4. 与刑事侦查、起诉、审判和执行判决等直接相关的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">5. 我们有充分证据表明用户存在主观恶意或滥用权利的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">6. 出于维护用户或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">7. 响应用户的请求将导致用户或其他个人、组织的合法权益受到严重损害的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">8. 涉及商业秘密的。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">七、我们如何保护未成年人的信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">我们的产品和服务主要面向成年人。</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">如您是未成年人，请您不要注册和使用我们的服务。</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">如果我们发现自己在未事先核验年龄的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">八、通知和修订</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">1、为给您提供更好的服务以及随着</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">业务的发展，本隐私政策也会随之更新。</span><span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">我们会通过在</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">客户端上发出更新版本并在生效前通过公告或以其他适当方式提醒您相关内容的更新，也请您访问</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">以便及时了解最新的隐私政策。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">本隐私政策所指的重大变更包括但不限于：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">（3）个人信息共享、转让或公开披露的主要对象发生变化；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">（6）个人信息安全影响评估报告表明存在高风险时。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">3、我们还会将本隐私政策的旧版本存档，供您查阅。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;font-weight: bold;letter-spacing: 0;vertical-align: baseline">九、如何联系我们</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">1、如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请联系我们。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">联系电话：010-56831900</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 4px 0;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">2、一般情况下，我们将在十五日内回复。如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向北京仲裁委员会按照其仲裁规则进行仲裁来寻求解决方案。仲裁结果是终局性的对双方具有约束力。</span>
</p>
<p class="paragraph text-align-type-left" style="margin: 4px 0;font-family: 等线;font-size: 16px;line-height: 16px">
    <br/>
</p>
<p>
    <br/>
</p>`,
	}),
};
</script>
