<template>
	<div class="agreement">
		<cjl-ys
			v-if="$store.getters['user/getAppid'] === 'wx41c1352ffcf95479'"
		></cjl-ys>
		<yzy-ys v-else></yzy-ys>
	</div>
</template>

<script>
import YzyYs from "./components/yzy/ys.vue";
import CjlYs from "./components/cjl/ys.vue";
export default {
	components: {
		YzyYs,
		CjlYs,
	},
};
</script>
